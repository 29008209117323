<template>
  <div :class="$style.container">
    <form :class="$style.card" @submit.prevent="submitForm">
      <h2 :class="$style.title">Создать дилера</h2>
      <Input
        v-model="form.name"
        :class="$style.input"
        :error="errors.name"
        placeholder="Название компании"
      />
      <Input
        v-model="form.email"
        :class="$style.input"
        :error="errors.email"
        placeholder="Email"
      />
      <Select
        v-model="form.type"
        :class="$style.input"
        :options="$options.DEALERS_TYPES"
        :error="errors.type"
        placeholder="Тип бизнеса"
      />
      <Select
        v-model="form.managerAccountId"
        :class="$style.input"
        :options="managers"
        placeholder="Менеджеры"
      />
      <Select
        v-model="form.coordinatorId"
        :class="$style.input"
        :options="coordinators"
        placeholder="Координаторы"
      />
      <Select
        v-model="form.dealerCatalogId"
        :class="$style.input"
        :options="catalogs"
        placeholder="Каталоги"
      />
      <Input
        v-model="form.tgChannelId"
        :class="$style.input"
        :error="errors.name"
        placeholder="ID telegram чата"
      />
      <Input
        v-model="form.paymentDelayDays"
        :class="$style.input"
        :error="errors.paymentDelayDays"
        placeholder="Отсрочка платежа (дней)"
      />
      <Input
        v-model="form.commodityCredit"
        :class="$style.input"
        :error="errors.commodityCredit"
        placeholder="Товарный кредит (руб)"
      />
      <div :class="$style.action">
        <Button type="submit">Создать дилера</Button>
        <div v-if="error" :class="$style.error">
          {{ error }}
        </div>
      </div>
      <Loader :isLoading="isLoading" />
    </form>
  </div>
</template>

<script>
import regexp from '@/helpers/regexp.js'
import delivery from '@/delivery'
import notifications from '@/mixins/notifications.js'
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { DEALERS_TYPES } from '@/constants/dealers'

import Input from '@/components/atoms/Input.vue'
import Select from '@/components/atoms/Select.vue'
import Button from '@/components/atoms/Button.vue'
import Loader from '@/components/atoms/LoadingBar.vue'

export default {
  components: {
    Input,
    Select,
    Button,
    Loader,
  },
  DEALERS_TYPES,
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      isLoading: false,
      error: '',
      form: {
        name: '',
        type: null,
        email: '',
        managerAccountId: null,
        dealerCatalogId: null,
        coordinatorId: null,
        tgChannelId: '',
        isBonusProgramMember: true,
        paymentDelayDays: null,
        commodityCredit: null,
      },
      errors: {
        name: '',
        type: '',
        email: '',
        paymentDelayDays: '',
        commodityCredit: '',
      },
      managers: [],
      coordinators: [],
      catalogs: [],
    }
  },
  mounted() {
    this.getManagers()
    this.getCatalogs()
    this.getCoordinators()
  },
  methods: {
    resetForm() {
      this.form.name = ''
      this.form.type = null
      this.form.email = ''
      this.form.managerAccountId = null
      this.form.dealerCatalogId = null
      this.form.coordinatorId = null
      this.form.tgChannelId = ''
      this.form.paymentDelayDays = null
      this.form.commodityCredit = null
      this.form.isBonusProgramMember = true
    },
    clearErros() {
      this.error = ''
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },
    isFormValid() {
      let isValid = true
      if (!this.form.name) {
        isValid = false
        this.errors.name = 'заполните это поле'
      }
      if (!this.form.type) {
        isValid = false
        this.errors.type = 'выберите тип бизнеса'
      }
      if (!this.form.email) {
        isValid = false
        this.errors.email = 'заполните это поле'
      } else if (!regexp.email.test(this.form.email)) {
        isValid = false
        this.errors.email = 'некорректный e-mail'
      }
      if (
        this.form.paymentDelayDays &&
        !(
          Number(this.form.paymentDelayDays) &&
          Number.isInteger(Number(this.form.paymentDelayDays))
        )
      ) {
        isValid = false
        this.errors.paymentDelayDays =
          'Отсрочка в днях должна быть целым числом'
      }

      if (this.form.commodityCredit && !Number(this.form.commodityCredit)) {
        isValid = false
        this.errors.commodityCredit = 'Товарный кредит должен быть числом'
      }

      return isValid
    },
    async submitForm() {
      this.clearErros()
      this.isLoading = true
      if (this.isFormValid()) {
        const { error } = await delivery.AddwineCore.DealersActions.create({
          ...this.form,
          paymentDelayDays: Number(this.form.paymentDelayDays),
          commodityCredit: Number(this.form.commodityCredit),
        })

        if (error) {
          this.error = error
          return
        }

        this.$router.push(ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST)

        this.resetForm()

        this.showNotification('Дилер успешно создан', 'success')
      }
      this.isLoading = false
    },
    async getManagers() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.ManagersActions.getList({
          page: 1,
          limit: 1000000,
          coordinators: false,
        })
      if (error) return
      this.managers = value.data.map((item) => {
        return { text: item.name, value: item.id }
      })
      this.managers.unshift({ text: 'Менеджер по умолчанию', value: '' })
      this.isLoading = false
    },
    async getCoordinators() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.ManagersActions.getList({
          page: 1,
          limit: 1000000,
          coordinators: true,
        })
      if (error) return
      this.coordinators = value.data.map((item) => {
        return { text: item.name, value: item.id }
      })
      this.coordinators.unshift({ text: 'Координатор по умолчанию', value: '' })
      this.isLoading = false
    },
    async getCatalogs() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.CatalogsActions.getList({
          page: 1,
          limit: 1000000,
        })
      if (error) return
      this.catalogs = value.data.map((item) => {
        return { text: item.name, value: item.id }
      })
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: 78.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  flex: 1 1 auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .card {
    max-width: 38.5rem;
    margin: 0 auto;
    padding: 2rem;
    background: $white;
    .title,
    .input {
      margin-bottom: 1.5rem;
      color: $black-gray;
    }
    .input {
      &.agree {
        a {
          color: $dark-orange;
        }
      }
    }
    .action {
      display: flex;
      align-items: center;
      .error {
        @include errorInscription;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
